import MobileMenuItem from "./MobileMenuItem";
import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const addJobIcon = require("../image/icon/add-job_ic.svg");
const candidateIcon = require("../image/icon/candidate_ic.svg");
const codingAssessment = require("../image/icon/coding_assessment.svg");
const chatIcon = require("../image/icon/chat_ic.svg");
const closeIcon = require("../image/icon/close_ic.svg");
const faqIcon = require("../image/icon/faq_ic.svg");
const feedbackIcon = require("../image/icon/feedback_ic.svg");
const homeIcon = require("../image/icon/home_ic.svg");
const moreIcon = require("../image/icon/more_ic.svg");
const screeningQuestionIcon = require("../image/icon/screening-question_ic.svg");
const teamIcon = require("../image/icon/team_ic.svg");
const workflowIcon = require("../image/icon/workflow_ic.svg");
const greenArrowIcon = require("../image/icon/right-green-arrow_ic.svg");
const jobsIcon = require("../image/icon/jobs_ic.svg");
const companyIcon = require("../image/icon/companies.svg");
const matchJobsIcon = require("../image/icon/match.svg");
const savedJobsIcon = require("../image/icon/bookmark.svg");
const fileIcon = require("../image/icon/file_ic.svg");
const domainIcon = require("../image/icon/domain_ic.svg");
const databasesIcon = require("../image/icon/databases_ic.svg");
const reviewIcon = require("../image/icon/review_ic.svg");
const starIcon = require("../image/icon/star_ic.svg");
const mailIcon = require("../image/icon/mail_ic.svg");
const calendarIcon = require("../image/icon/calendar_ic.svg");
const InterviewIcon = require("../image/icon/Interview.svg");
const OfferIcon = require("../image/icon/offericon.svg");
const screeningIcon = require("../image/icon/screening.svg");
const drugScreeningIcon = require("../image/icon/drugScreening.svg");
const IntegrationIcon = require("../image/icon/Inetgration.svg");


const candidatePageData = [
  {
    title: "Job Search",
    submenu: [
      {
        label: "Home",
        to: "/",
        icon: homeIcon,
      },
      {
        label: "Jobs",
        to: "/candidate/jobs",
        icon: jobsIcon,
      },
      {
        label: "Companies",
        to: "/candidate/companies",
        icon: companyIcon,
      },
      {
        label: "Matched Jobs",
        to: "/candidate/jobs?type=match",
        icon: matchJobsIcon,
      },
      {
        label: "Saved Jobs",
        to: "/candidate/jobs?type=save",
        icon: savedJobsIcon,
      },
    ],
  },
  {
    title: "Assessment",
    submenu: [
      {
        label: "Scoreboard",
        to: "/candidate/scoreboard",
        icon: starIcon,
      }
    ],
  },
  {
    title: "Screening & Hiring",
    submenu: [
      {
        label: "Offers",
        to: "/candidate/offers",
        icon: OfferIcon,
      }
    ],
  },
]

const MobileMenu = ({ mobileMenu, setMobile }) => {
  const {onAuthorizedRoutes} = useHasPermission();
  const pageData = [
    {
      title: "Dashboard",
      submenu: [
        {
          label: "Home",
          to: "/",
          icon: homeIcon,
        },
        {
          label: "Mail Box",
          to: "/recruitment/mail-box",
          icon: mailIcon,
        },
        // {
        //   label: "Calendar",
        //   to: "/candidate/meeting-appointment",
        //   icon: calendarIcon,
        // },
        onAuthorizedRoutes("job-post",["view","add","edit"]) ? {
          label: "Jobs",
          to: "/recruitment/jobs",
          icon: jobsIcon,
        }:null,
        // {
        //   label: "Files",
        //   to: "files",
        //   icon: fileIcon,
        // },
      ].filter(item=>!!item),
    },
    {
      title: "Recruitment",
      submenu: [
        {
          label: "Recruitment",
          to: "/recruitment/home",
          icon: homeIcon,
        },
        onAuthorizedRoutes("job-post","add") ?{
          label: "To post Job",
          to: "/job/create",
          icon: addJobIcon,
        }:null,
        onAuthorizedRoutes("team-members",["view","add","edit"]) ? {
          label: "Team Members",
          to: "/recruitment/manage-team-members",
          icon: teamIcon,
        }:null,
        onAuthorizedRoutes("job-post",["view","add","edit"]) ?{
          label: "Workflows",
          to: "/recruitment/position-workflow",
          icon: workflowIcon,
        }:null,
        // {
        //   label: 'Screening questions',
        //   to: '/screening-questions',
        //   icon: screeningQuestionIcon
        // },
        onAuthorizedRoutes("job-post",["view","add","edit"]) ? 
          {
          label: "Jobs",
          to: "/recruitment/jobs",
          icon: jobsIcon,
        }:null,
        // {
        //   label: "Candidates",
        //   to: "/recruitment/candidate-profile",
        //   icon: candidateIcon,
        // },
      ].filter(item=>!!item),
    },
    onAuthorizedRoutes("assessment",["view","add","edit"]) ? {
      title: "Assessments",
      submenu: [
        {
          label: "Assessments",
          to: "/recruitment/assessments",
          icon: homeIcon,
        },
        onAuthorizedRoutes(["job-post","assessment"],"view","AND") && onAuthorizedRoutes('assessment','edit') ?{
          label: "Assignment",
          to: "/recruitment/manage-assignment",
          icon: fileIcon,
        }:null,
        {
          label: "Domain Assessments",
          to: "/assessment/domain-assessment",
          icon: domainIcon,
        },
        {
          label: "Coding Assessments",
          to: "/coding-assessments",
          icon: codingAssessment,
        },
        // {
        //   label: "Databases",
        //   to: "/databases",
        //   icon: databasesIcon,
        // },
        onAuthorizedRoutes(["job-post","assessment"],"view","AND") && onAuthorizedRoutes('assessment','edit') ?{
          label: "Review & Score",
          to: "/recruitment/review-score",
          icon: reviewIcon,
        }:null,
        onAuthorizedRoutes('job-post','view') ?{
          label: "Scoreboard",
          to: "/recruitment/scoreboard",
          icon: starIcon,
        }:null,
      ].filter(item=>!!item),
    }:null,
    onAuthorizedRoutes('job-post','view') ?{
      title: "Screening & Hiring",
      submenu: [
        {
          label: "Home",
          to: "/recruitment/screening-hiring",
          icon: homeIcon,
        },
        onAuthorizedRoutes("job-post",["view","edit"]) ?{
          label: "Interviews",
          to: "/recruitment/interviews/jobs",
          icon: InterviewIcon,
        }:null,
        onAuthorizedRoutes(["job-post","offer"],"view","AND") && onAuthorizedRoutes("offer",["delete","add","edit"]) ?{
          label: "Offer",
          to: "/recruitment/offers",
          icon: OfferIcon,
        }:null,
        onAuthorizedRoutes(["job-post","background"],"view","AND") && onAuthorizedRoutes("background",["add","edit"]) ? {
          label: "Background Screening",
          to: "/recruitment/background-screening/jobs",
          icon: screeningIcon,
        }:null,
        onAuthorizedRoutes(["job-post","drug"],"view","AND") && onAuthorizedRoutes("drug",["add","edit"]) ? {
          label: "Drug Screening",
          to: "/recruitment/drug-screening/jobs",
          icon: drugScreeningIcon,
        }:null,
        // {
        //   label: "Integration",
        //   to: "/recruitment/screening-hiring",
        //   icon: IntegrationIcon,
        // },
      ].filter(item=>!!item),
    } : null,
    // {
    //   title: "HR Analytics",
    //   submenu: [],
    // },
    // {
    //   title: "Support",
    //   submenu: [
    //     {
    //       label: "Help center",
    //       to: "/faq",
    //       icon: faqIcon,
    //     },
    //     {
    //       label: "Live chat",
    //       to: "/live-chat",
    //       icon: chatIcon,
    //     },
    //     {
    //       label: "Feedbacks",
    //       to: "/live-coding",
    //       icon: feedbackIcon,
    //     },
    //   ],
    // },
  ].filter(item=>!!item);
  
  return (
    <div className={`${mobileMenu ? "mobile-menu active" : "mobile-menu"}`}>
      <ul className="mobile-menu__list">
        {onAuthorizedRoutes("candidate") ? (
          <>
            {candidatePageData?.map((item, index) => {
              return (
                <MobileMenuItem setMobile={setMobile} key={index} item={item} />
              );
            })}
          </>
        ) : (
          <>
            {pageData.map((item, index) => {
              return (
                <MobileMenuItem setMobile={setMobile} key={index} item={item} />
              );
            })}
          </>
        )}
      </ul>
    </div>
  );
};

export default MobileMenu;
